import request from '@/utils/request'

//查询全部订单活动
export function getActiveOrder(data) {
    return request({
      url: '/order/listPage',
      method: 'post',
      data
    })
  }
  //查询全部会员订单
export function getVipOrder(data) {
    return request({
      url: '/order/listPageMember',
      method: 'post',
      data
    })
  }
  