<template>
  <div class="orderManagement">
    <el-card class="order-card">
      <el-tabs v-model="activeNumber" @tab-click="handleClick">
        <el-tab-pane label="活动订单" name="first">
          <OrderActive  />
          </el-tab-pane>
            
       <el-tab-pane label="会员订单" name="vip">
         <OrderVIP />
       </el-tab-pane>
      
       </el-tabs>
    </el-card>

  </div>
</template>


<script>
 import OrderActive from './components/OrderActive'
 import OrderVIP from './components/OrderVIP'




export default {
  name: 'orderManagement',
   components: {
    OrderActive,
    
    OrderVIP,
},
  data() {
    return {
     activeNumber: 'first',
    }
  },
  
  methods: {
    handleClick() {
      // console.log(tab, event);
      // this.name = this.tab.name
    }
  }
}

</script> 

<style scoped lang="less">
.orderManagement {
  width: 100%;
  overflow: auto;
  background: #E5E5E5;

  .order-card{
    border-radius: 12px;
    margin: 16px ;
  }
}
</style>
