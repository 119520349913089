<template>
    <div class="orderActive">
        <el-form :inline="true">
            <el-form-item label="订单编号：">
                <el-input v-model="queryInfo.orderNumber" placeholder="请输入订单编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="活动名称：">
                <el-input v-model="queryInfo.activityName" placeholder="请输入活动名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="下单日期：" class="startime">
                <div class="timePicker">
                    <el-date-picker type="datetime" placeholder="开始时间" v-model="queryInfo.beginTime"
                        class="middle-date-picker" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" />
                </div>
            </el-form-item>
            <span style="padding: 0px 10px">~</span>
            <el-form-item prop="endTime">
                <el-date-picker type="datetime" placeholder="结束时间" v-model="queryInfo.deadline"
                    class="middle-date-picker" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" />
            </el-form-item>
            <el-form-item>
                <el-button @click="reset">重置</el-button>

            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="tableData" height="602" :header-cell-style="cellStyle"
            :default-sort="{ prop: 'createTime', order: 'ascending' }">
            <el-table-column prop="createTime" label="创建时间" sortable align="center" />
            <el-table-column prop="orderNumber" label="订单编号" width="200" align="center" />
            <el-table-column prop="activityName" label="活动名称" width="400" align="center" />
            <el-table-column width="260" label="商品信息" align="center">
                <template v-slot="{ row }">
                    <el-image :src="row.activityCover" />
                </template>
            </el-table-column>
            <el-table-column prop="activePrice" width="100" label="金额" align="center">
                <template v-slot="{ row }">￥{{ row.activePrice }}</template>
            </el-table-column>
            <el-table-column prop="wechatNickname" label="付款账号" align="center">

            </el-table-column>
            <el-table-column prop="userName" label="真实姓名" align="center">

            </el-table-column>
            <el-table-column prop="whetherMember" label="用户等级" align="center">
                <template v-slot="{ row }">{{ row.whetherMember == 1 ? '会员用户' : '普通用户' }}</template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">退款
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="new_page">
            <el-pagination :current-page="queryInfo.current" :page-sizes="[10, 30, 50]" :page-size="queryInfo.size"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script>
import { getActiveOrder } from '@/api/orderManagement.js'
const defaultQueryInfo = Object.freeze({
    activityId: 1,
    activityName: "",
    beginTime: "",
    deadline: "",
    current: 1,
    orderNumber: "",
    size: 10,

})
export default {
    name: 'OrderManagement',

    data() {
        return {

            activeNumber: 'first',
            startTime: '',
            endTime: '',

            tableData: [],
            cellStyle: {
                background: '#F6F6F6',
                color: '#333333'
            },

            queryInfo: { ...defaultQueryInfo },

            total: 0,

        }
    },
    created() {
        this.search()
    },
    methods: {
        handleClick() {

        },
        //重置
        reset() {
            this.queryInfo.current = 1
            this.queryInfo.activityName = ""
            this.queryInfo.orderNumber = ""
            this.queryInfo.beginTime = ""
            this.queryInfo.deadline = ""
            this.search()
        },
        //搜索
        async search() {
            const { data: res } = await getActiveOrder(this.queryInfo)
            this.tableData = res.data.list
            this.total = res.data.total

        },
        //每页的条数
        handleSizeChange(val) {
            this.queryInfo.size = val
            this.search()
        },
        //当前页数
        handleCurrentChange(val) {
            this.queryInfo.current = val
            this.search()
        },
        //退款
        handleDelete() {

        }
    }

}


</script>
<style lang="less" scoped>
.orderActive {
    .startime {
        margin-right: 0;
    }

    /deep/.el-table .ascending .sort-caret.ascending {
        border-bottom-color: #333333;
    }

    /deep/.el-button--text {
        color: #7C71E1
    }

    /deep/.el-image__inner {
        width: 100px;
    }

    .new_page {
        width: 100%;
        text-align: center;
        margin: 30px auto;
    }
}
</style>