<template>
  <div class="ordervip">
    <el-form :inline="true">
      <el-form-item label="订单编号：">
        <el-input v-model="queryInfo.orderNumber" placeholder="请输入订单编号" clearable></el-input>
      </el-form-item>
      <el-form-item label="真实姓名:">
        <el-input v-model="queryInfo.userName" placeholder="请输入真实姓名" clearable></el-input>
      </el-form-item>
      <el-form-item label="下单日期：" class="startime">
        <div class="timePicker">
          <el-date-picker type="datetime" placeholder="开始时间" v-model="queryInfo.beginTime" class="middle-date-picker"
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" />
        </div>
      </el-form-item>
      <span style="padding: 0px 10px">~</span>
      <el-form-item prop="deadline">
        <el-date-picker type="datetime" placeholder="结束时间" v-model="queryInfo.deadline" class="middle-date-picker"
          format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" height="602" :header-cell-style="cellStyle"
      :default-sort="{ prop: 'createTime', order: 'ascending' }">
      <el-table-column prop="createTime" label="创建时间" sortable align="center" />
      <el-table-column prop="orderNumber" label="订单编号" width="175" align="center" />
      <el-table-column prop="wechatNickname" label="付款账号" width="300" align="center" />
      <el-table-column prop="userName" width="130" label="真实姓名" align="center"/>

     
      <el-table-column prop="mobile" label="手机号" align="center" />
      <el-table-column prop="institution" label="机构" align="center"/>

      <el-table-column prop="position" label="职位" align="center"/>

      
      <el-table-column prop="originalPrice" label="金额" align="center">
        <template v-slot="{ row }">￥{{ row.originalPrice }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">

          <el-button size="mini" type="text" @click="handleDelete(scope.$index, scope.row)">退款
          </el-button>
        </template>
      </el-table-column>
    </el-table>
   <div class="new_page">
        <el-pagination
          :current-page="queryInfo.current"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
  </div>
      
  </div>
</template>
<script>
import { getVipOrder } from '@/api/orderManagement.js'
const defaultQueryInfo = Object.freeze({
  beginTime: "",//生效时间
  current: 1,//页数
  deadline: "",//截止时间
  orderNumber: "",//订单编号
  size: 10,
  userName: ""
})
export default {
  name: 'OrderVip',
  data() {
    return {
     tableData: [],
      cellStyle: {
         background: '#F6F6F6',
         color:'#333333',
       
      },
      pageNumber: 10,
      pageSize: 10,
      queryInfo: { ...defaultQueryInfo },
      total: null,
    }
  },
  created() {
    this.search()
  },
  methods: {
    //重置方法
    reset() {
      this.queryInfo.orderNumber = ""
      this.queryInfo.userName = ""
      this.queryInfo.beginTime = ""
      this.queryInfo.deadline = ""
      this.search()
    },
    //搜索方法
    async search() {

      const { data: res } = await getVipOrder(this.queryInfo)
      this.tableData = res.data.list
      console.log(this.tableData)
      this.total = res.data.total

    },
     //每页的条数
    handleSizeChange(val) {
      this.queryInfo.size = val
      this.search()
    },
     //当前页数
    handleCurrentChange(val) {
       this.queryInfo.current = val
      this.search()
    }
  }
}
</script>
<style lang="less" scoped>
.ordervip{
  .startime{
    margin-right: 0px;
  }
  /deep/.el-table .ascending .sort-caret.ascending{
    border-bottom-color:#333333;
  }
  /deep/.el-button--text{
        color:#7C71E1
    }
     .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
}
</style>